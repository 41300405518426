.slide1 {
    animation: fade 2s;
}
.slide2 {
    animation: fade2 2s;
}
@keyframes fade {
    0% {
        opacity: 0;
        z-index: 2;
    }
    33.333% {
        opacity: 1;
        z-index: 1;
    }
    66.666% {
        opacity: 1;
        z-index: 1;
    }
    100% {
        opacity: 1;
        z-index: 2;
    }
}
@keyframes fade2 {
    0% {
        opacity: 0;
        z-index: 1;
    }
    33.333% {
        opacity: 1;
        z-index: 2;
    }
    66.666% {
        opacity: 1;
        z-index: 2;
    }
    100% {
        opacity: 1;
        z-index: 1;
    }
}
